import DIRECTIONS from "./constant/Directions";
import CIRCLE_BREAK_POINT from "./constant/CircleBreakPoints";
import BORDERS from "./constant/Borders";

const Helpers = {
    /**
     * @param {Coords} first
     * @param {Coords} second
     * @return {number}
     */
    getDistance(first, second) {
        const a = first.getX() - second.getX();
        const b = first.getY() - second.getY();
        return Math.sqrt(a * a + b * b);
    },

    /**
     * @param {Coords} start
     * @param {number} distance
     * @param {string} direction
     * @return {Coords}
     */
    getCoordsWithDistanceInGivenDirection(start, distance, direction) {
        const coords = start.clone();
        switch (direction) {
            case DIRECTIONS.LEFT:
                coords.decX(distance);
                break;
            case DIRECTIONS.RIGHT:
                coords.incX(distance);
                break;
            case DIRECTIONS.UP:
                coords.decY(distance);
                break;
            case DIRECTIONS.DOWN:
                coords.incY(distance);
                break;
        }
        return coords;
    },

    /**
     * @param {number} min
     * @param {number} max
     * @return {number}
     */
    getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min)) + min;
    },

    /**
     * @param {string} direction
     * @return {string}
     */
    getOppositeDirection(direction) {
        switch (direction) {
            case DIRECTIONS.LEFT:
                return DIRECTIONS.RIGHT;
            case DIRECTIONS.RIGHT:
                return DIRECTIONS.LEFT;
            case DIRECTIONS.UP:
                return DIRECTIONS.DOWN;
            case DIRECTIONS.DOWN:
                return DIRECTIONS.UP;
        }
        return direction;
    },

    /**
     * @param {number} circleBreakPoint
     * @return {number}
     */
    mapCircleBreakPointToRadians(circleBreakPoint) {
        switch (circleBreakPoint) {
            case CIRCLE_BREAK_POINT.FIRST:
                return Math.PI / 2;
            case CIRCLE_BREAK_POINT.SECOND:
                return Math.PI;
            case CIRCLE_BREAK_POINT.THIRD:
                return Math.PI * 1.5;
            case CIRCLE_BREAK_POINT.FOURTH:
                return Math.PI * 2;
        }
        return 0;
    },

    /**
     * @param {number} breakPoint
     * @param {boolean} antiClockwise
     * @return {number}
     */
    getNextCircleBreakPoint(breakPoint, antiClockwise = false) {
        if (antiClockwise) {
            if (breakPoint === CIRCLE_BREAK_POINT.ZERO) {
                return CIRCLE_BREAK_POINT.THIRD;
            }
            return breakPoint - 1;
        }
        if (breakPoint === CIRCLE_BREAK_POINT.FOURTH) {
            return CIRCLE_BREAK_POINT.FIRST;
        }
        return breakPoint + 1;
    },

    /**
     * @param {Coords} point
     * @param {string} border
     * @param {CanvasSize} canvasSize
     * @return {Coords}
     */
    movePointThroughBorder(point, border, canvasSize) {
        const newPoint = point.clone();
        switch (border) {
            case BORDERS.LEFT:
                newPoint.setX(canvasSize.getMaxWidth() - Math.abs(point.getX()));
                break;
            case BORDERS.RIGHT:
                newPoint.setX(point.getX() - canvasSize.getMaxWidth());
                break;
            case BORDERS.UP:
                newPoint.setY(canvasSize.getMaxHeight() - Math.abs(point.getY()));
                break;
            case BORDERS.DOWN:
                newPoint.setY(point.getY() - canvasSize.getMaxHeight());
                break;
        }
        return newPoint;
    }
}

export default Helpers;
