import DIRECTIONS from "../../constant/Directions";
import Path from "../../render/Path";

/**
 * @param {Coords} start
 * @param {string} direction
 * @constructor
 */
const SnakeMoveLine = function (start, direction) {
    this.start = start;
    this.end = start.clone();
    this.length = 0;
    this.direction = direction;
}

/**
 * @param {number} distance
 * @return {boolean}
 */
SnakeMoveLine.prototype.incLength = function (distance = 1) {
    if (this.applyMovementToCoords(this.end, this.direction, distance)) {
        this.length += distance;
        return true;
    }
    return false;
}

/**
 * @param {number} distance
 * @return {boolean}
 */
SnakeMoveLine.prototype.decLength = function (distance = 1) {
    if (this.applyMovementToCoords(this.start, this.direction, distance)) {
        this.length -= distance;
        return true;
    }
    return false;
}

/**
 * @return {Coords}
 */
SnakeMoveLine.prototype.getStart = function () {
    return this.start.clone();
}

/**
 * @return {Coords}
 */
SnakeMoveLine.prototype.getEnd = function () {
    return this.end.clone();
}

/**
 * @return {number}
 */
SnakeMoveLine.prototype.getLength = function () {
    return this.length;
}

/**
 * @param {Path} path
 * @return {SnakeMoveLine}
 */
SnakeMoveLine.prototype.addToPath = function (path) {
    path.moveTo(this.start);
    path.lineTo(this.end);
    return this;
}

/**
 * @return {Path}
 */
SnakeMoveLine.prototype.generatePath = function () {
    const path = new Path(this.start);
    path.lineTo(this.end);
    return path;
}

/**
 * @private
 * @param {Coords} coords
 * @param {string} direction
 * @param {number} distance
 * @return {boolean}
 */
SnakeMoveLine.prototype.applyMovementToCoords = function (coords, direction, distance) {
    switch (this.direction) {
        case DIRECTIONS.LEFT:
            coords.decX(distance);
            return true;
        case DIRECTIONS.RIGHT:
            coords.incX(distance);
            return true;
        case DIRECTIONS.UP:
            coords.decY(distance);
            return true;
        case DIRECTIONS.DOWN:
            coords.incY(distance);
            return true;
    }
    return false;
}

/**
 * @return {SnakeMoveLine}
 */
SnakeMoveLine.prototype.clone = function () {
    return new SnakeMoveLine(this.start.clone(), this.direction);
}

/**
 * @param {Coords} start
 */
SnakeMoveLine.prototype.setStart = function (start) {
    this.start = start;
    this.end = start.clone();
    this.length = 0;
}

export default SnakeMoveLine;
