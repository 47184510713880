/**
 * @param {Snake} snake
 * @param {number} score
 * @constructor
 */
const Player = function (snake, score = 0) {
    this.snake = snake;
    snake.setPlayer(this);
    this.score = score;
}

/**
 * @return {number}
 */
Player.prototype.getScore = function () {
    return this.score;
}

/**
 * @param points
 * @return {Player}
 */
Player.prototype.incScore = function (points = 1) {
    this.score += points;
    return this;
}

/**
 * @return {Snake}
 */
Player.prototype.getSnake = function () {
    return this.snake;
}

/**
 * @param {string} newDirection
 * @return {Player}
 */
Player.prototype.changeSnakeDirection = function (newDirection) {
    this.getSnake().changeDirection(newDirection);
    return this;
}

/**
 * @return {string}
 */
Player.prototype.getSnakeDirection = function () {
    return this.getSnake().getDirection();
}

export default Player;
