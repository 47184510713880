import {createRouter, createWebHistory} from 'vue-router';
import HomeView from '../views/HomeView.vue';
import SnakeGameView from '@/views/SnakeGameView';

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
    },
    {
        path: '/game/snake',
        name: 'game_snake',
        component: SnakeGameView,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
