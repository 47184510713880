<template>
  <div class="vertical-center">
    <div class="center">
      <h2 class="main">Dawid Zaroda</h2>
      <h3 class="main">Web Developer</h3>
<!--      <router-link :to="{name: 'games'}" class="main-btn">Mini gry</router-link>-->
<!--      <router-link :to="{name: 'games'}" class="main-btn">Kontakt</router-link>-->
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
};
</script>

<style>
.main {
  text-align: center;
}

h2.main {
  text-transform: uppercase;
  font-size: 3em;
}

h3.main {
  font-size: 2em;
}
.center {
  margin: auto;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 45%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
  overflow: clip;
}

.main-btn {
  padding: 9px;
  background-color: #000;
  border: 0;
  color: #f1f1f1;
  display: block;
  margin: 10px auto;
  width: 200px;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 18px;
  border-radius: 4px;
  text-decoration: none;
  text-align: center;
}

.main-btn:hover {
  background-color: #050505;
}
</style>