/**
 * @param {number} x
 * @param {number} y
 * @constructor
 */
const Coords = function (x, y) {
    this.x = x;
    this.y = y;
}

/**
 * @return {Coords}
 */
Coords.prototype.clone = function () {
    return new Coords(this.x, this.y);
};

/**
 * @return {number}
 */
Coords.prototype.getX = function () {
    return this.x;
}

/**
 * @param {number} x
 * @return {Coords}
 */
Coords.prototype.setX = function (x) {
    this.x = x;
    return this;
}

/**
 * @return {number}
 */
Coords.prototype.getY = function () {
    return this.y;
}

/**
 * @param {number} y
 * @return {Coords}
 */
Coords.prototype.setY = function (y) {
    this.y = y;
    return this;
}

/**
 * @param {number} distance
 * @return {Coords}
 */
Coords.prototype.incX = function (distance) {
    this.x += distance;
    return this;
}

/**
 * @param {number} distance
 * @return {Coords}
 */
Coords.prototype.incY = function (distance) {
    this.y += distance;
    return this;
}

/**
 * @param {number} distance
 * @return {Coords}
 */
Coords.prototype.decX = function (distance) {
    this.x -= distance;
    return this;
}

/**
 * @param {number} distance
 * @return {Coords}
 */
Coords.prototype.decY = function (distance) {
    this.y -= distance;
    return this;
}
/**
 * @return {[number, number]}
 */
Coords.prototype.get = function () {
    return [this.x, this.y];
}

export default Coords;

