const GAME_SETTINGS = {
    SNAKE_COLOR: [255, 150, 0],
    SNAKE_WIDTH: 10,
    SNAKE_START_LENGTH: 150,
    SNAKE_SPEED: 2,
    SNAKE_BORDER_WIDTH: 2,
    SNAKE_BORDER_COLOR: [0, 0, 0],
    SNAKE_HOLE_COLOR: [0, 0, 0],
    APPLE_BORDER_COLOR: [0, 0, 0],
    APPLE_COLOR: [255, 0, 0],
    APPLE_LEAF_COLOR: [13, 150, 0],
    APPLE_PETIOLE_COLOR: [128, 128, 128],
    SCORE_FOR_APPLE: 1,
    LENGTH_FOR_APPLE: 15,
    MOVE_INTERVAL: 20,
    DRAW_INTERVAL: 17, // ~60 FPS
    SCORE_FONT: '700 56px monospace',
    SCORE_POS: [10, 0] // only first pos is now supported
};

export default GAME_SETTINGS;
