/**
 * @param {number} minWidth
 * @param {number} minHeight
 * @param {number} maxWidth
 * @param {number} maxHeight
 * @constructor
 */
const CanvasSize = function (minWidth, minHeight, maxWidth, maxHeight) {
    this.minWidth = minWidth;
    this.minHeight = minHeight;
    this.maxWidth = maxWidth;
    this.maxHeight = maxHeight;
}

/**
 * @return {number}
 */
CanvasSize.prototype.getMinWidth = function () {
    return this.minWidth;
}

/**
 * @return {number}
 */
CanvasSize.prototype.getMinHeight = function () {
    return this.minHeight;
}

/**
 * @return {number}
 */
CanvasSize.prototype.getMaxWidth = function () {
    return this.maxWidth;
}

/**
 * @return {number}
 */
CanvasSize.prototype.getMaxHeight = function () {
    return this.maxHeight;
}

export default CanvasSize;
