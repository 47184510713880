import Color from "./Color";
import DRAW_STYLES from "../constant/DrawStyles";

/**
 * @constructor
 */
const DrawStyle = function () {
    this.style = DRAW_STYLES.FILLED;
    this.strokeColor = new Color(0, 0, 0);
    this.fillColor = new Color(255, 255, 255);
    this.lineWidth = 1;
    this.font = '48px serif';
}

/**
 * @param style
 * @return {DrawStyle}
 */
DrawStyle.prototype.setStyle = function (style) {
    this.style = style;
    return this;
}

/**
 * @return {string}
 */
DrawStyle.prototype.getStyle = function () {
    return this.style;
}

/**
 * @param {Color} color
 * @return {DrawStyle}
 */
DrawStyle.prototype.setFillColor = function (color) {
    this.fillColor = color;
    return this;
}

/**
 * @return {Color}
 */
DrawStyle.prototype.getFillColor = function () {
    return this.fillColor;
}

/**
 * @param {Color} color
 * @return {DrawStyle}
 */
DrawStyle.prototype.setStrokeColor = function (color) {
    this.strokeColor = color;
    return this;
}

/**
 * @return {Color}
 */
DrawStyle.prototype.getStrokeColor = function () {
    return this.strokeColor;
}

/**
 * @param {number} width
 * @return {DrawStyle}
 */
DrawStyle.prototype.setLineWidth = function (width) {
    this.lineWidth = width;
    return this;
}

/**
 * @return {number}
 */
DrawStyle.prototype.getLineWidth = function () {
    return this.lineWidth;
}

/**
 * @param font
 * @return {DrawStyle}
 */
DrawStyle.prototype.setFont = function (font) {
    this.font = font;
    return this;
}

/**
 * @return {string}
 */
DrawStyle.prototype.getFont = function () {
    return this.font;
}

export default DrawStyle;

export const DrawStyleFactory = {
    /**
     * @param object
     * @return {DrawStyle}
     */
    fromDataObject(object) {
        const drawStyle = new DrawStyle();
        return drawStyle
            .setStyle(object.style)
            .setFillColor(new Color(object.fillColor.red, object.fillColor.green, object.fillColor.blue, object.fillColor.alpha))
            .setStrokeColor(new Color(object.strokeColor.red, object.strokeColor.green, object.strokeColor.blue, object.strokeColor.alpha))
            .setLineWidth(object.lineWidth)
            .setFont(object.font)
    },

    /**
     * @param {number} width
     * @param {Color} color
     * @return {DrawStyle}
     */
    lineStyle(width, color) {
        const drawStyle = new DrawStyle();
        return drawStyle.setStyle(DRAW_STYLES.STROKED).setLineWidth(width).setStrokeColor(color);
    },

    /**
     * @param {Color} color
     * @return {DrawStyle}
     */
    areaStyle(color) {
        const drawStyle = new DrawStyle();
        return drawStyle.setStyle(DRAW_STYLES.FILLED).setFillColor(color);
    },

    /**
     * @param {Color} areaColor
     * @param {Color} strokeColor
     * @param {number} strokeWidth
     * @return {DrawStyle}
     */
    areaWithStrokeStyle(areaColor, strokeColor, strokeWidth) {
        const drawStyle = new DrawStyle();
        return drawStyle.setStyle(DRAW_STYLES.BOTH).setFillColor(areaColor).setStrokeColor(strokeColor).setLineWidth(strokeWidth);
    },

    /**
     * @param {string} font
     * @param {Color} color
     */
    textStyle(font, color) {
        const drawStyle = new DrawStyle();
        return drawStyle.setStyle(DRAW_STYLES.FILLED).setFillColor(color).setFont(font);
    },

    /**
     * @param {string} font
     * @param {Color} color
     * @param {Color} strokeColor
     */
    textWithStrokeStyle(font, color, strokeColor) {
        const drawStyle = new DrawStyle();
        return drawStyle.setStyle(DRAW_STYLES.BOTH).setFillColor(color).setStrokeColor(strokeColor).setFont(font);
    }
};
