import GAME_STATES from "./constant/GameStates";
import GAME_SETTINGS from "./constant/GameSettings";
import Apple from "./objects/Apple";
import Color from "./render/Color";
import FADE_STATE from "./constant/FadingStates";

/**
 * @param {CanvasTarget} target
 * @property {Player[]} players
 * @property {Snake[]} snakes
 * @property {Apple[]} apples
 * @constructor
 */
const Game = function (target) {
    this.target = target;
    this.moveInterval = -1;
    this.drawInterval = -1;
    this.stateListeners = [];
    this.scoreListeners = [];

    this.players = [];
    this.snakes = [];
    this.apples = [];

    this.setState(GAME_STATES.INITIALIZED);
}

/**
 * @param {Player} player
 * @return {Game}
 */
Game.prototype.addPlayer = function (player) {
    this.players.push(player);
    this.snakes.push(player.getSnake());
    return this;
}

/**
 * @param {Apple} apple
 * @return {Game}
 */
Game.prototype.addApple = function (apple) {
    this.apples.push(apple);
    return this;
}

Game.prototype.start = function () {
    if (!this.players.length) {
        throw new Error('Players was not added.');
    }
    this.moveInterval = setInterval(this.move.bind(this), GAME_SETTINGS.MOVE_INTERVAL);
    this.drawInterval = setInterval(this.draw.bind(this), GAME_SETTINGS.DRAW_INTERVAL);

    this.setState(GAME_STATES.PLAYING);
}

Game.prototype.stop = function () {
    if (this.moveInterval !== -1) {
        clearInterval(this.moveInterval);
        this.moveInterval = -1;
    }
    if (this.drawInterval !== -1) {
        clearInterval(this.drawInterval);
        this.drawInterval = -1;
    }

    this.setState(GAME_STATES.STOPPED);
}

/**
 * @return {string}
 */
Game.prototype.getState = function () {
    return this.state;
}

/**
 * @param {string} state
 * @return {Game}
 */
Game.prototype.setState = function (state) {
    let oldState = this.state;
    this.state = state;
    for (let idx = 0; idx < this.stateListeners.length; idx++) {
        this.stateListeners[idx](state, oldState);
    }
    return this;
}

/**
 * @param {function} callback
 * @return {Game}
 */
Game.prototype.onStateChange = function (callback) {
    this.stateListeners.push(callback);
    return this;
}

/**
 * @param {function} callback
 * @return {Game}
 */
Game.prototype.onScoreUpdate = function (callback) {
    this.scoreListeners.push(callback);
    return this;
}

/**
 * @private
 * @param {Player} player
 */
Game.prototype.sendScoreUpdate = function (player) {
    for (let idx = 0; idx < this.scoreListeners.length; idx++) {
        this.scoreListeners[idx](player);
    }
}

/**
 * @return {Apple}
 */
Game.prototype.generateApple = function () {
    return new Apple(
        this.target.getRandomCoords(),
        new Color(...GAME_SETTINGS.APPLE_COLOR),
        new Color(...GAME_SETTINGS.APPLE_PETIOLE_COLOR),
        new Color(...GAME_SETTINGS.APPLE_LEAF_COLOR),
        new Color(...GAME_SETTINGS.APPLE_BORDER_COLOR)
    );
}

Game.prototype.move = function () {
    // move snakes
    for (let idx = 0; idx < this.snakes.length; idx++) {
        this.snakes[idx].move(this.target.getSize());
    }

    // check collisions
    this.checkCollisions();
}

Game.prototype.checkCollisions = function () {
    for (let idx = 0; idx < this.snakes.length; idx++) {
        this.checkCollisionWithApples(this.snakes[idx]);
        this.checkCollisionWithSnakes(this.snakes[idx]);
    }
}

/**
 * @param {Snake} snake
 */
Game.prototype.checkCollisionWithApples = function (snake) {
    const newApples = [];
    for (let idx = 0; idx < this.apples.length; idx++) {
        const appleState = this.apples[idx].getState();
        if (appleState === FADE_STATE.VISIBLE && (this.apples[idx].isCollidingWithCoords(this.target, snake.getLeftSideCoords()) || this.apples[idx].isCollidingWithCoords(this.target, snake.getRightSideCoords()))) {
            snake.getPlayer().incScore(GAME_SETTINGS.SCORE_FOR_APPLE);
            snake.incLength(GAME_SETTINGS.LENGTH_FOR_APPLE);
            this.apples[idx].hide();
            newApples.push(this.generateApple());
            this.sendScoreUpdate(snake.getPlayer());
        } else if (appleState === FADE_STATE.HIDING) {
            this.apples[idx].decreaseAlpha();
        }
        if (appleState !== FADE_STATE.HIDDEN) {
            newApples.push(this.apples[idx]);
        }
    }
    this.apples = newApples;
}

/**
 * @param snake
 */
Game.prototype.checkCollisionWithSnakes = function (snake) {
    if (snake.isCollidingWithHimself(this.target)) {
        this.stop();
    }
    for (let idx = 0; idx < this.snakes.length; idx++) {
        if (this.snakes[idx] === snake) {
            continue;
        }
        if (this.snakes[idx].isCollidingWithCoords(this.target, snake.getCoords())) {
            this.stop();
        }
    }
}

/**
 * @return {Apple[]}
 */
Game.prototype.getApples = function () {
    return this.apples;
}

Game.prototype.draw = function () {
    // clear target
    this.target.clear();

    // draw snakes
    for (let idx = 0; idx < this.snakes.length; idx++) {
        this.snakes[idx].draw(this.target);
    }

    // draw apples
    for (let idx = 0; idx < this.apples.length; idx++) {
        this.apples[idx].draw(this.target);
    }
}

export default Game;
