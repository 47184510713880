import {DrawStyleFactory} from "../../render/DrawStyle";
import Path from "../../render/Path";
import FADE_STATE from "../../constant/FadingStates";

/**
 * @param {Coords} center
 * @param {number} radius
 * @param {Color} color
 * @param {Color|null} strokeColor
 * @param {number|null} strokeWidth
 * @constructor
 */
const Hole = function (center, radius, color, strokeColor = null, strokeWidth = null) {
    this.drawStyle = strokeColor === null ? DrawStyleFactory.areaStyle(color) : DrawStyleFactory.areaWithStrokeStyle(color, strokeColor, strokeWidth);
    this.state = FADE_STATE.VISIBLE;
    this.path = new Path();
    this.path.arc(center, radius, 0, Math.PI * 2);
}

/**
 * @return {number}
 */
Hole.prototype.getState = function () {
    return this.state;
}

/**
 * @return {Hole}
 */
Hole.prototype.hide = function () {
    this.state = FADE_STATE.HIDING;
    return this;
}

/**
 * @return {Hole}
 */
Hole.prototype.decreaseAlpha = function () {
    const step = 0.05;
    this.drawStyle.getFillColor().decAlpha(step);
    this.drawStyle.getStrokeColor().decAlpha(step);
    if (this.drawStyle.getFillColor().isInvisible()) {
        this.state = FADE_STATE.HIDDEN;
    }
    return this;
}

/**
 * @param {CanvasTarget} target
 * @return {Hole}
 */
Hole.prototype.draw = function (target) {
    target.drawPathWithStyle(this.path, this.drawStyle);
    return this;
}

export default Hole;
