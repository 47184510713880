import Path from "../../render/Path";
import Helpers from "../../Helpers";

/**
 * @constructor
 * @param {Coords} center
 * @param {string} direction
 * @param {number} startBreakPoint
 * @param {number} radius
 * @param {number} turnDistance
 * @param {boolean} antiClockwise
 */
const SnakeMoveArc = function (center, direction, startBreakPoint, radius, turnDistance, antiClockwise = false) {
    this.center = center;
    this.direction = direction;
    this.startBreakPoint = startBreakPoint;
    this.startAngle = Helpers.mapCircleBreakPointToRadians(startBreakPoint);
    this.endAngle = this.startAngle;
    this.roundedEndAngle = Helpers.mapCircleBreakPointToRadians(Helpers.getNextCircleBreakPoint(startBreakPoint, antiClockwise));
    this.radius = radius;
    this.length = 0;
    this.antiClockwise = antiClockwise;
    this.turnDistance = turnDistance;

    this.stepAngle = (Math.PI / 2) / turnDistance; // arc quarter / whole arc distance
}

/**
 * @private
 * @return {SnakeMoveArc}
 */
SnakeMoveArc.prototype.correctRoundings = function () {
    this.endAngle = this.roundedEndAngle;
    return this;
}

/**
 * @param {number} distance
 * @return {boolean}
 */
SnakeMoveArc.prototype.incLength = function (distance = 1) {
    this.applyDistanceToEndAngle(distance);
    this.length += distance;
    if (this.length >= this.turnDistance) {
        this.correctRoundings();
    }
    return false;
}

/**
 * @param {number} distance
 * @return {SnakeMoveArc}
 */
SnakeMoveArc.prototype.applyDistanceToStartAngle = function (distance = 1) {
    if (this.antiClockwise) {
        this.startAngle -= this.stepAngle * distance;
    } else {
        this.startAngle += this.stepAngle * distance;
    }
    return this;
}

/**
 * @param {number} distance
 * @return {SnakeMoveArc}
 */
SnakeMoveArc.prototype.applyDistanceToEndAngle = function (distance = 1) {
    if (this.antiClockwise) {
        this.endAngle -= this.stepAngle * distance;
    } else {
        this.endAngle += this.stepAngle * distance;
    }
    return this;
}

/**
 * @param {number} distance
 * @return {boolean}
 */
SnakeMoveArc.prototype.decLength = function (distance = 1) {
    this.applyDistanceToStartAngle(distance);
    this.length -= distance;
    return false;
}

/**
 * @return {string}
 */
SnakeMoveArc.prototype.getDirection = function () {
    return this.direction;
}

/**
 * @return {Coords}
 */
SnakeMoveArc.prototype.getStart = function () {
    const start = this.center.clone();
    return start
        .incX(this.radius * Math.cos(this.startAngle))
        .incY(this.radius * Math.sin(this.startAngle));
}

/**
 * @return {Coords}
 */
SnakeMoveArc.prototype.getEnd = function () {
    const end = this.center.clone();
    return end
        .incX(this.radius * Math.cos(this.endAngle))
        .incY(this.radius * Math.sin(this.endAngle));
}

/**
 * @return {number}
 */
SnakeMoveArc.prototype.getLength = function () {
    return this.length;
}

/**
 * @return {Path}
 */
SnakeMoveArc.prototype.generatePath = function () {
    const path = new Path();
    path.arc(this.center, this.radius, this.startAngle, this.endAngle, this.antiClockwise);
    return path;
}

/**
 * @return {SnakeMoveArc}
 */
SnakeMoveArc.prototype.clone = function () {
    const copy = new SnakeMoveArc(
        this.center.clone(),
        this.direction,
        this.startBreakPoint,
        this.radius,
        this.turnDistance,
        this.antiClockwise
    );
    copy.setLength(this.length);
    return copy;
}

/**
 * @return {Coords}
 */
SnakeMoveArc.prototype.getCenter = function () {
    return this.center;
}

/**
 * @param {Coords} center
 * @return {SnakeMoveArc}
 */
SnakeMoveArc.prototype.setCenter = function (center) {
    this.center = center;
    return this;
}

/**
 * @param {number} length
 * @return {SnakeMoveArc}
 */
SnakeMoveArc.prototype.setLength = function (length) {
    this.applyDistanceToEndAngle(length);
    this.length = length;
    return this;
}

export default SnakeMoveArc;
