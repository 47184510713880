import DRAW_STYLES from "../constant/DrawStyles";
import Coords from "../Coords";
import CanvasSize from "./CanvasSize";
import Helpers from "../Helpers";

/**
 * @param {HTMLCanvasElement} element
 * @constructor
 */
const CanvasTarget = function (element) {
    this.canvas = element;
    if (!element || typeof element.getContext !== 'function') {
        throw new Error('Invalid HTML element passed, expected CANVAS.');
    }
    this.ctx = element.getContext('2d');
    this.center = new Coords(this.getWidth() / 2, this.getHeight() / 2);
    this.size = new CanvasSize(0, 0, this.getWidth(), this.getHeight());
}

/**
 * @return {number}
 */
CanvasTarget.prototype.getWidth = function () {
    return this.canvas.width;
}

/**
 * @return {number}
 */
CanvasTarget.prototype.getHeight = function () {
    return this.canvas.height;
}

/**
 * @return {[number, number]}
 */
CanvasTarget.prototype.getDimensions = function () {
    return [this.canvas.width, this.canvas.height];
}

/**
 *  @return {Coords}
 */
CanvasTarget.prototype.getCenter = function () {
    return this.center;
}

/**
 * @return {CanvasSize}
 */
CanvasTarget.prototype.getSize = function () {
    return this.size;
}

/**
 * @return {Coords}
 */
CanvasTarget.prototype.getRandomCoords = function () {
    return new Coords(
        Helpers.getRandomInt(0, this.getWidth()),
        Helpers.getRandomInt(0, this.getHeight())
    );
}

/**
 * Clears render area
 * @return {CanvasTarget}
 */
CanvasTarget.prototype.clear = function () {
    this.ctx.clearRect(0, 0, ...this.getDimensions());
    return this;
}

/**
 * @param {Path} path
 * @param {DrawStyle} drawStyle
 * @return {CanvasTarget}
 */
CanvasTarget.prototype.drawPathWithStyle = function (path, drawStyle) {
    const style = drawStyle.getStyle();

    if (style === DRAW_STYLES.STROKED || style === DRAW_STYLES.BOTH) {
        this.ctx.lineWidth = drawStyle.getLineWidth();
        this.ctx.strokeStyle = drawStyle.getStrokeColor().full();
        this.ctx.stroke(path.getPath2D());
    }
    if (style === DRAW_STYLES.FILLED || style === DRAW_STYLES.BOTH) {
        this.ctx.fillStyle = drawStyle.getFillColor().full();
        this.ctx.fill(path.getPath2D());
    }
    return this;
};

/**
 * @param {Text} text
 * @param {DrawStyle} drawStyle
 * @return {CanvasTarget}
 */
CanvasTarget.prototype.drawTextWithStyle = function (text, drawStyle) {
    const style = drawStyle.getStyle();
    const [x, y] = text.getCoords().get();

    this.ctx.font = drawStyle.getFont();
    if (style === DRAW_STYLES.STROKED || style === DRAW_STYLES.BOTH) {
        this.ctx.lineWidth = drawStyle.getLineWidth();
        this.ctx.strokeStyle = drawStyle.getStrokeColor().full();
        this.ctx.strokeText(text.getText(), x, y);//, text.getMaxWidth());
    }
    if (style === DRAW_STYLES.FILLED || style === DRAW_STYLES.BOTH) {
        this.ctx.fillStyle = drawStyle.getFillColor().full();
        this.ctx.fillText(text.getText(), x, y);//, text.getMaxWidth());
    }
    return this;
}

/**
 * @param {Path[]} pathCollection
 * @param {DrawStyle} drawStyle
 * @return {CanvasTarget}
 */
CanvasTarget.prototype.drawPathCollectionWithStyle = function (pathCollection, drawStyle) {
    if (!pathCollection.length) {
        return this;
    }
    for (let idx = 0; idx < pathCollection.length; idx++) {
        this.drawPathWithStyle(pathCollection[idx], drawStyle);
    }
    return this;
}

/**
 * @param {Path|Path[]} path
 * @param {Coords} coords
 * @return {boolean}
 */
CanvasTarget.prototype.isPointCollidingWithPathStroke = function (path, coords) {
    if (Array.isArray(path)) {
        for (let idx = 0; idx < path.length; idx++) {
            if (this.ctx.isPointInStroke(path[idx].getPath2D(), coords.getX(), coords.getY())) {
                return true;
            }
        }
        return false;
    }
    return this.ctx.isPointInStroke(path.getPath2D(), coords.getX(), coords.getY());
}

/**
 * @param {Path} path
 * @param {Coords} coords
 * @return {boolean}
 */
CanvasTarget.prototype.isPointCollidingWithPath = function (path, coords) {
    return this.ctx.isPointInPath(path.getPath2D(), coords.getX(), coords.getY());
}

export default CanvasTarget;
