<template>
  <router-view/>
</template>

<style>
html {
  height: 100%;
}

body {
  background: rgb(0, 0, 0);
  background: linear-gradient(146deg, rgba(0, 0, 0, 1) 0%, rgba(28, 28, 28, 1) 39%, rgba(0, 0, 0, 1) 100%);
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-family: "Courier New", monospace;
  overflow: clip;
}
</style>