<template>
  <div>
    <canvas ref="canvas" :width="400" :height="600" style="border: 1px solid black"/>
    <div>Wynik: {{ score }}</div>
  </div>
</template>

<script>
import {ref, onMounted} from 'vue';
import SinglePlayer from '../../node_modules/game-snake/src/game/SinglePlayer';

export default {
  name: 'SnakeGameView',
  setup() {
    let game = null;
    const canvas = ref(null);
    const score = ref(0);

    const onKeyDown = (e) => {
      e.preventDefault();
      if (game !== null) {
        game.onKeyDown(e.key);
      }
    };

    const onScoreUpdate = (player) => {
      score.value = player.getScore();
    };
    onMounted(() => {
      document.addEventListener('keydown', onKeyDown, false);
      game = new SinglePlayer(canvas.value);
      game.initNewGame();
      game.start();
      game.onScoreUpdate(onScoreUpdate);
    });
    return {
      canvas,
      score,
    };
  },
};
</script>

<style scoped>
div {
  background: rgb(255, 255, 255);
  color: #000;
  font-family: "Courier New", monospace;
  width: 100%;
  height: 100%;
}
</style>