import {DrawStyleFactory} from "../render/DrawStyle";
import Path from "../render/Path";
import FADE_STATE from "../constant/FadingStates";

/**
 * @param {Coords} coords
 * @param {Color} color
 * @param {Color} petioleColor
 * @param {Color} leafColor
 * @param {Color} borderColor
 * @constructor
 */
const Apple = function (coords, color, petioleColor, leafColor, borderColor) {
    this.coords = coords;
    this.state = FADE_STATE.VISIBLE;

    this.apple = this.createApple();
    this.appleDrawStyle = DrawStyleFactory.areaWithStrokeStyle(color, borderColor, 2);
    this.petiole = this.createPetiole();
    this.petioleDrawStyle = DrawStyleFactory.lineStyle(2, petioleColor);
    this.petioleBorderDrawStyle = DrawStyleFactory.lineStyle(3, borderColor);
    this.leaf = this.createLeaf();
    this.leafDrawStyle = DrawStyleFactory.areaWithStrokeStyle(leafColor, borderColor, 2);
}

/**
 * @return {{leafColor: Color, borderColor: Color, color: Color, petioleColor: Color, coords: Coords}}
 */
Apple.prototype.exportAsDataObject = function () {
    return {
        coords: this.coords,
        color: this.appleDrawStyle.getFillColor(),
        petioleColor: this.petioleDrawStyle.getStrokeColor(),
        leafColor: this.leafDrawStyle.getFillColor(),
        borderColor: this.appleDrawStyle.getStrokeColor()
    }
}

/**
 * @return {string}
 */
Apple.prototype.getState = function () {
    return this.state;
}

/**
 * @private
 * @return {Path}
 */
Apple.prototype.createApple = function () {
    const start = this.coords.clone().decY(6);
    const path = new Path(start);
    path.bezierCurveTo( // left side
        this.coords.clone().decX(12).decY(15),
        this.coords.clone().decX(8).incY(12),
        this.coords.clone().decX(2).incY(8)
    );
    path.bezierCurveTo( // bottom
        this.coords.clone().decX(1).incY(7),
        this.coords.clone().incX(1).incY(7),
        this.coords.clone().incX(2).incY(8)
    );
    path.bezierCurveTo( // right side
        this.coords.clone().incX(8).incY(12),
        this.coords.clone().incX(12).decY(15),
        start
    );
    path.close();
    return path;
}

/**
 * @private
 * @return {Path}
 */
Apple.prototype.createLeaf = function () {
    const start = this.coords.clone().decY(8);
    const path = new Path(start);
    path.bezierCurveTo(
        start.clone().decY(4).incX(2),
        start.clone().decY(5).incX(5),
        start.clone().decY(4).incX(8)
    );
    path.bezierCurveTo(
        start.clone().incY(1).incX(4),
        start.clone().incY(2).incX(3),
        start
    );
    return path;
}

/**
 * @private
 * @return {Path}
 */
Apple.prototype.createPetiole = function () {
    const start = this.coords.clone().decY(6);
    const path = new Path(start);
    path.lineTo(start.decY(5).decX(1));
    return path;
}

/**
 * @return {Coords}
 */
Apple.prototype.getCoords = function () {
    return this.coords.clone();
}

/**
 * @return {Apple}
 */
Apple.prototype.hide = function () {
    this.state = FADE_STATE.HIDING;
    this.decreaseAlpha();
    return this;
}

/**
 * @return {Apple}
 */
Apple.prototype.decreaseAlpha = function () {
    const decAlphaStep = 0.05;
    this.leafDrawStyle.getFillColor().decAlpha(decAlphaStep);
    this.leafDrawStyle.getStrokeColor().decAlpha(decAlphaStep);
    this.petioleDrawStyle.getStrokeColor().decAlpha(decAlphaStep);
    this.petioleBorderDrawStyle.getStrokeColor().decAlpha(decAlphaStep);
    this.appleDrawStyle.getStrokeColor().decAlpha(decAlphaStep);
    if (this.appleDrawStyle.getFillColor().decAlpha(decAlphaStep).isInvisible()) {
        this.state = FADE_STATE.HIDDEN;
    }
    return this;
}

/**
 * @param {CanvasTarget} target
 * @return {Apple}
 */
Apple.prototype.draw = function (target) {
    target.drawPathWithStyle(this.apple, this.appleDrawStyle);
    target.drawPathWithStyle(this.petiole, this.petioleBorderDrawStyle);
    target.drawPathWithStyle(this.petiole, this.petioleDrawStyle);
    target.drawPathWithStyle(this.leaf, this.leafDrawStyle);
    return this;
}

/**
 * @param {CanvasTarget} target
 * @param {Coords} coords
 * @return {boolean}
 */
Apple.prototype.isCollidingWithCoords = function (target, coords) {
    return target.isPointCollidingWithPath(this.apple, coords);
}

export default Apple;
