/**
 * @param {?Coords} start
 * @constructor
 */
const Path = function (start = null) {
    this.path2d = new Path2D();
    if (start !== null) {
        this.moveTo(start);
    }
}

/**
 * @param {Coords} to
 * @return {Path}
 */
Path.prototype.moveTo = function (to) {
    this.path2d.moveTo(...to.get());
    return this;
}

/**
 * @param {Coords} to
 * @return {Path}
 */
Path.prototype.lineTo = function (to) {
    this.path2d.lineTo(...to.get());
    return this;
}

/**
 * @param {Coords} firstControlPoint
 * @param {Coords} secondControlPoint
 * @param {Coords} endPoint
 * @return {Path}
 */
Path.prototype.bezierCurveTo = function (firstControlPoint, secondControlPoint, endPoint) {
    this.path2d.bezierCurveTo(
        ...firstControlPoint.get(),
        ...secondControlPoint.get(),
        ...endPoint.get()
    );
    return this;
}

/**
 * @param {Coords} center
 * @param {number} radius
 * @param {number} startAngle
 * @param {number} endAngle
 * @param {boolean} antiClockwise
 * @return {Path}
 */
Path.prototype.arc = function (center, radius, startAngle, endAngle, antiClockwise = false) {
    const [x, y] = center.get();
    this.path2d.arc(x, y, radius, startAngle, endAngle, antiClockwise);
    return this;
}

/**
 * @return {Path2D}
 */
Path.prototype.getPath2D = function () {
    return this.path2d;
}

/**
 * @return {Path}
 */
Path.prototype.close = function () {
    this.path2d.closePath();
    return this;
}

export default Path;
