/**
 * @param {number} red
 * @param {number} green
 * @param {number} blue
 * @param {number} alpha
 * @constructor
 */
const Color = function (red, green, blue, alpha = 1) {
    this.red = red;
    this.green = green;
    this.blue = blue;
    this.alpha = alpha;
}

/**
 * @return {string}
 */
Color.prototype.base = function () {
    return 'rgb(' + [this.red, this.green, this.blue].join(',') + ')';
}

/**
 * @return {string}
 */
Color.prototype.full = function () {
    return 'rgba(' + [this.red, this.green, this.blue, this.alpha].join(',') + ')';
}

/**
 * @param {number} points
 * @return {Color}
 */
Color.prototype.decAlpha = function (points) {
    this.alpha -= points;
    return this;
}

/**
 * @return {boolean}
 */
Color.prototype.isInvisible = function () {
    return this.alpha <= 0;
}

/**
 * @return {Color}
 */
Color.prototype.clone = function () {
    return new Color(
        this.red,
        this.green,
        this.blue,
        this.alpha
    );
}

export default Color;
