import CanvasTarget from "./render/CanvasTarget";
import Game from "./Game";
import Player from "./Player";
import GAME_SETTINGS from "./constant/GameSettings";
import DIRECTIONS from "./constant/Directions";
import Color from "./render/Color";
import Snake from "./objects/snake/Snake";
import Coords from "./Coords";
import Apple from "./objects/Apple";
import FADE_STATE from "./constant/FadingStates";

/**
 * @param {HTMLCanvasElement} element
 * @constructor
 */
const SinglePlayer = function (element) {
    this.target = new CanvasTarget(element);
    this.game = new Game(this.target);
}

SinglePlayer.prototype.initNewGame = function () {
    const snake = new Snake();
    snake.initNew(
        this.target.getCenter(),
        GAME_SETTINGS.SNAKE_START_LENGTH,
        GAME_SETTINGS.SNAKE_SPEED,
        DIRECTIONS.RIGHT,
        new Color(...GAME_SETTINGS.SNAKE_COLOR),
        GAME_SETTINGS.SNAKE_WIDTH,
        GAME_SETTINGS.SNAKE_BORDER_WIDTH,
        new Color(...GAME_SETTINGS.SNAKE_BORDER_COLOR)
    );
    this.player = new Player(snake);

    this.game.addPlayer(this.player);
    this.game.addApple(this.game.generateApple());
}

/**
 * @param {object} dataObject
 */
SinglePlayer.prototype.initGameFromDataObject = function (dataObject) {
    const snake = new Snake();
    snake.initFromDataObject(dataObject.snake);

    this.player = new Player(snake, dataObject.score);
    this.game.addPlayer(this.player);

    const apple = new Apple(
        new Coords(dataObject.apple.coords.x, dataObject.apple.coords.y),
        new Color(dataObject.apple.color.red, dataObject.apple.color.green, dataObject.apple.color.blue, dataObject.apple.color.alpha),
        new Color(dataObject.apple.petioleColor.red, dataObject.apple.petioleColor.green, dataObject.apple.petioleColor.blue, dataObject.apple.petioleColor.alpha),
        new Color(dataObject.apple.leafColor.red, dataObject.apple.leafColor.green, dataObject.apple.leafColor.blue, dataObject.apple.leafColor.alpha),
        new Color(dataObject.apple.borderColor.red, dataObject.apple.borderColor.green, dataObject.apple.borderColor.blue, dataObject.apple.borderColor.alpha)
    );
    this.game.addApple(apple);
}

SinglePlayer.prototype.exportToDataObject = function () {
    const gameApples = this.game.getApples();
    let apple;
    for (let i = 0; i < gameApples.length; i++) {
        if (gameApples[i].getState() === FADE_STATE.VISIBLE) {
            apple = gameApples[i];
            break;
        }
    }
    return {
        snake: this.player.getSnake().exportToDataObject(),
        score: this.getPlayerScore(),
        apple: apple.exportAsDataObject(),
    }
}

SinglePlayer.prototype.start = function () {
    this.game.start();
}

SinglePlayer.prototype.stop = function () {
    this.game.stop();
}

/**
 * @return {number}
 */
SinglePlayer.prototype.getPlayerScore = function () {
    return this.player.getScore();
}

/**
 * @param {function} callback
 * @return {SinglePlayer}
 */
SinglePlayer.prototype.onStateChange = function (callback) {
    this.game.onStateChange(callback);
    return this;
}

/**
 * @param {function} callback
 * @return {SinglePlayer}
 */
SinglePlayer.prototype.onScoreUpdate = function (callback) {
    this.game.onScoreUpdate(callback);
    return this;
}

/**
 * @param {string} key
 */
SinglePlayer.prototype.onKeyDown = function (key) {
    switch (key) {
        case 'ArrowLeft':
            this.player.changeSnakeDirection(DIRECTIONS.LEFT);
            break;
        case 'ArrowRight':
            this.player.changeSnakeDirection(DIRECTIONS.RIGHT);
            break;
        case 'ArrowUp':
            this.player.changeSnakeDirection(DIRECTIONS.UP);
            break;
        case 'ArrowDown':
            this.player.changeSnakeDirection(DIRECTIONS.DOWN);
            break;
    }
}

SinglePlayer.prototype.onTap = function (event) {
    const x = event.pageX - this.target.canvas.offsetLeft,
        y = event.pageY - this.target.canvas.offsetTop;

    const firstSection = 0.25 * this.target.getWidth();
    const lastSection = this.target.getWidth() - firstSection;
    if (x < firstSection) { // left
        this.player.changeSnakeDirection(DIRECTIONS.LEFT);
    } else if (x > lastSection) { // right
        this.player.changeSnakeDirection(DIRECTIONS.RIGHT);
    } else if (y < (this.target.getHeight() / 2)) { // up
        this.player.changeSnakeDirection(DIRECTIONS.UP);
    } else { // down
        this.player.changeSnakeDirection(DIRECTIONS.DOWN);
    }
    // const currentDirection = this.player.getSnakeDirection();
    // if (currentDirection === DIRECTIONS.UP || currentDirection === DIRECTIONS.DOWN) {
    //     const center = this.target.getWidth() / 2;
    //     if (x <= center) { // left
    //         this.player.changeSnakeDirection(DIRECTIONS.LEFT);
    //     } else { // right
    //         this.player.changeSnakeDirection(DIRECTIONS.RIGHT);
    //     }
    // } else if (currentDirection === DIRECTIONS.LEFT || currentDirection === DIRECTIONS.RIGHT) {
    //     const center = this.target.getHeight() / 2;
    //     if (y <= center) { // top
    //         this.player.changeSnakeDirection(DIRECTIONS.UP);
    //     } else { // down
    //         this.player.changeSnakeDirection(DIRECTIONS.DOWN);
    //     }
    // }
}

/**
 * @param {string} direction
 */
SinglePlayer.prototype.changeSnakeDirection = function (direction) {
    this.player.changeSnakeDirection(direction);
}

export default SinglePlayer;
