import {DrawStyleFactory} from "../../render/DrawStyle";
import Helpers from "../../Helpers";
import SnakeMoveLine from "./SnakeMoveLine";
import DIRECTIONS from "../../constant/Directions";
import SnakeMoveArc from "./SnakeMoveArc";
import CIRCLE_BREAK_POINT from "../../constant/CircleBreakPoints";
import Color from "../../render/Color";
import BORDERS from "../../constant/Borders";
import Coords from "../../Coords";
import Hole from "./Hole";
import FADE_STATE from "../../constant/FadingStates";
import GAME_SETTINGS from "../../constant/GameSettings";

const Snake = function () {
    this.movement = [];
    this.movementQueue = [];
    this.pathCollection = [];
}

/**
 * @param {Coords} start
 * @param {number} length
 * @param {number} speed
 * @param {string} direction
 * @param {Color} color
 * @param {number} width
 * @param {number} borderWidth
 * @param {Color} borderColor
 * @return {Snake}
 */
Snake.prototype.initNew = function (start, length, speed, direction, color, width, borderWidth, borderColor) {
    this.coords = start;
    this.length = length;
    this.creditLength = length;
    this.speed = speed;
    this.direction = direction;
    this.turnRadius = (width / 2) + 1;

    this.borderDrawStyle = DrawStyleFactory.lineStyle(width + (borderWidth * 2), borderColor);
    this.drawStyle = DrawStyleFactory.lineStyle(width, color);
    this.movement.unshift(new SnakeMoveLine(this.coords.clone(), this.direction));
    this.needRedraw = true;

    // should be always equal to turnRadius * 2 for smooth moving
    this.turnDistance = this.turnRadius * 2;

    /** @var {Hole|null} this.hole **/
    this.hole = new Hole(this.coords.clone(), width, new Color(...GAME_SETTINGS.SNAKE_HOLE_COLOR));

    return this;
}

Snake.prototype.initFromDataObject = function (dataObject) {
    this.coords = new Coords(dataObject.coords.x, dataObject.coords.y);
    this.length = dataObject.length;
    this.creditLength = dataObject.creditLength;
    this.speed = dataObject.speed;
    this.direction = dataObject.direction;
    this.turnRadius = dataObject.turnRadius;
    this.turnDistance = dataObject.turnDistance;
    this.needRedraw = dataObject.needRedraw;
    this.movementQueue = dataObject.movementQueue;

    this.borderDrawStyle = DrawStyleFactory.fromDataObject(dataObject.borderDrawStyle);
    this.drawStyle = DrawStyleFactory.fromDataObject(dataObject.drawStyle);

    for (let i = 0; i < dataObject.movement.length; i++) {
        const movementDataObject = dataObject.movement[i];
        if (typeof movementDataObject.center !== 'undefined') {
            const obj = new SnakeMoveArc(
                new Coords(movementDataObject.center.x, movementDataObject.center.y),
                movementDataObject.direction,
                movementDataObject.startBreakPoint,
                movementDataObject.radius,
                movementDataObject.turnDistance,
                movementDataObject.antiClockwise
            );
            obj.setLength(movementDataObject.length);
            this.movement.push(obj);
        } else {
            const obj = new SnakeMoveLine(new Coords(movementDataObject.start.x, movementDataObject.start.y), movementDataObject.direction);
            obj.incLength(movementDataObject.length);
            this.movement.push(obj);
        }
    }
    this.hole = null;

    return this;
}

Snake.prototype.exportToDataObject = function () {
    return {
        coords: this.coords,
        length: this.length,
        creditLength: this.creditLength,
        speed: this.speed,
        direction: this.direction,
        movement: this.movement,
        movementQueue: this.movementQueue,
        needRedraw: this.needRedraw,
        turnRadius: this.turnRadius,
        turnDistance: this.turnDistance,
        borderDrawStyle: this.borderDrawStyle,
        drawStyle: this.drawStyle
    };
}

/**
 * @param {Player} player
 * @return {Snake}
 */
Snake.prototype.setPlayer = function (player) {
    this.player = player;
    return this;
}

/**
 * @return {Player}
 */
Snake.prototype.getPlayer = function () {
    return this.player;
}

/**
 * @return {Coords}
 */
Snake.prototype.getCoords = function () {
    return this.coords.clone();
}

/**
 * @return {Coords}
 */
Snake.prototype.getLeftSideCoords = function () {
    const coords = this.coords.clone();
    if (this.direction === DIRECTIONS.UP || this.direction === DIRECTIONS.DOWN) {
        coords.decX(this.borderDrawStyle.getLineWidth() / 2);
    } else {
        coords.decY(this.borderDrawStyle.getLineWidth() / 2);
    }
    return coords;
}

/**
 * @return {Coords}
 */
Snake.prototype.getRightSideCoords = function () {
    const coords = this.coords.clone();
    if (this.direction === DIRECTIONS.UP || this.direction === DIRECTIONS.DOWN) {
        coords.incX(this.borderDrawStyle.getLineWidth() / 2);
    } else {
        coords.incY(this.borderDrawStyle.getLineWidth() / 2);
    }
    return coords;
}

/**
 * @return {DrawStyle}
 */
Snake.prototype.getDrawStyle = function () {
    return this.drawStyle;
}

/**
 * @return {DrawStyle}
 */
Snake.prototype.getBorderDrawStyle = function () {
    return this.borderDrawStyle;
}

/**
 * @param {number} length
 * @return {Snake}
 */
Snake.prototype.incLength = function (length) {
    this.length += length;
    this.creditLength += length;
    return this;
}
/**
 * @param {CanvasSize} canvasSize
 * @return {Snake}
 */
Snake.prototype.move = function (canvasSize) {
    // increase length (at start of drawing element)
    this.movement[0].incLength(this.speed);
    // update position
    this.coords = this.movement[0].getEnd();
    // decrease creditLength if exist or decrease length (at end of drawing element)
    if (this.creditLength > 0) {
        this.creditLength -= this.speed;
        if (this.creditLength < 0) {
            this.creditLength = 0;
        }
    } else {
        const lastIdx = this.movement.length - 1;
        this.movement[lastIdx].decLength(this.speed);
        if (this.movement[lastIdx].getLength() <= 0) {
            this.movement.pop();
        }
    }

    // check end of direction change
    if (this.movement[0] instanceof SnakeMoveArc && this.movement[0].getLength() >= this.turnDistance) {
        if (this.movementQueue.length > 0) {
            const newDirection = this.movementQueue.pop();
            this.movement.unshift(this.generateSnakeMoveArcForDirectionsPair(this.direction, newDirection));
            this.direction = newDirection;
        } else {
            this.movement.unshift(new SnakeMoveLine(this.movement[0].getEnd(), this.movement[0].getDirection()));
        }
    }

    // check movement through borders
    this.checkBorderMovement(canvasSize);
    this.needRedraw = true;
    this.updateHole();
    return this;
}

/**
 * @return {Snake}
 */
Snake.prototype.updateHole = function () {
    if (this.hole !== null) {
        if (!this.creditLength) {
            this.hole.hide();
        }
        if (this.hole.getState() === FADE_STATE.HIDING) {
            this.hole.decreaseAlpha();
        } else if (this.hole.getState() === FADE_STATE.HIDDEN) {
            this.hole = null;
        }
    }
    return this;
}

/**
 * @private
 * @param {CanvasSize} canvasSize
 * @return {boolean}
 */
Snake.prototype.checkBorderMovement = function (canvasSize) {
    if (this.coords.getX() < canvasSize.getMinWidth()) {
        this.moveThroughBorder(BORDERS.LEFT, canvasSize);
        return true;
    }
    if (this.coords.getY() < canvasSize.getMinHeight()) {
        this.moveThroughBorder(BORDERS.UP, canvasSize);
        return true;
    }
    if (this.coords.getX() > canvasSize.getMaxWidth()) {
        this.moveThroughBorder(BORDERS.RIGHT, canvasSize);
        return true;
    }
    if (this.coords.getY() > canvasSize.getMaxHeight()) {
        this.moveThroughBorder(BORDERS.DOWN, canvasSize);
        return true;
    }
    return false;
}

/**
 * @private
 * @param {string} border
 * @param {CanvasSize} canvasSize
 * @return {Snake}
 */
Snake.prototype.moveThroughBorder = function (border, canvasSize) {
    this.coords = Helpers.movePointThroughBorder(this.coords, border, canvasSize);
    this.addMovementThroughBorder(border, canvasSize);
    return this;
}

/**
 * @param {string} border
 * @param {CanvasSize} canvasSize
 * @return {boolean}
 */
Snake.prototype.addMovementThroughBorder = function (border, canvasSize) {
    const currentMovement = this.movement[0];
    const newMovement = currentMovement.clone();

    if (currentMovement instanceof SnakeMoveLine) {
        newMovement.setStart(this.coords.clone());
    }
    if (currentMovement instanceof SnakeMoveArc) {
        newMovement.setCenter(Helpers.movePointThroughBorder(currentMovement.getCenter(), border, canvasSize));
    }

    this.movement.unshift(newMovement);
    return true;
}

/**
 * @param {string} newDirection
 * @return {boolean}
 */
Snake.prototype.changeDirection = function (newDirection) {
    if (newDirection === this.direction || newDirection === Helpers.getOppositeDirection(this.direction)) {
        return false;
    }
    if (this.movement[0] instanceof SnakeMoveArc) {
        if (this.movementQueue.length >= 2) { // limit max direction change while direction is already changing
            return false;
        }
        if (!this.movementQueue.length) {
            this.movementQueue.unshift(newDirection);
        } else {
            const lastAddedDirectionToQueue = this.movementQueue.shift();
            // if in queue is direction and now we want to bad direction to direction from queue, we want to remove this badly added direction
            if (newDirection === lastAddedDirectionToQueue || newDirection === Helpers.getOppositeDirection(lastAddedDirectionToQueue)) {
                this.movementQueue.unshift(newDirection);
            } else {
                this.movementQueue.unshift(lastAddedDirectionToQueue);
                this.movementQueue.unshift(newDirection);
            }
        }
        return true;
    }
    this.movement.unshift(this.generateSnakeMoveArcForDirectionsPair(this.direction, newDirection));
    this.direction = newDirection;
    return true;
}

/**
 * @param {string} oldDirection
 * @param {string} newDirection
 * @return {SnakeMoveArc}
 */
Snake.prototype.generateSnakeMoveArcForDirectionsPair = function (oldDirection, newDirection) {
    const center = this.coords.clone();
    let startBreakPoint = -1;
    let clockwise = true;

    if (oldDirection === DIRECTIONS.DOWN && newDirection === DIRECTIONS.RIGHT) {
        center.incX(this.turnRadius);
        startBreakPoint = CIRCLE_BREAK_POINT.SECOND;
        clockwise = false;
    } else if (oldDirection === DIRECTIONS.DOWN && newDirection === DIRECTIONS.LEFT) {
        center.decX(this.turnRadius);
        startBreakPoint = CIRCLE_BREAK_POINT.ZERO;
        clockwise = true;
    } else if (oldDirection === DIRECTIONS.UP && newDirection === DIRECTIONS.RIGHT) {
        center.incX(this.turnRadius);
        startBreakPoint = CIRCLE_BREAK_POINT.SECOND;
        clockwise = true;
    } else if (oldDirection === DIRECTIONS.UP && newDirection === DIRECTIONS.LEFT) {
        center.decX(this.turnRadius);
        startBreakPoint = CIRCLE_BREAK_POINT.FOURTH;
        clockwise = false;
    } else if (oldDirection === DIRECTIONS.LEFT && newDirection === DIRECTIONS.UP) {
        center.decY(this.turnRadius);
        startBreakPoint = CIRCLE_BREAK_POINT.FIRST;
        clockwise = true;
    } else if (oldDirection === DIRECTIONS.LEFT && newDirection === DIRECTIONS.DOWN) {
        center.incY(this.turnRadius);
        startBreakPoint = CIRCLE_BREAK_POINT.THIRD;
        clockwise = false;
    } else if (oldDirection === DIRECTIONS.RIGHT && newDirection === DIRECTIONS.UP) {
        center.decY(this.turnRadius);
        startBreakPoint = CIRCLE_BREAK_POINT.FIRST;
        clockwise = false;
    } else if (oldDirection === DIRECTIONS.RIGHT && newDirection === DIRECTIONS.DOWN) {
        center.incY(this.turnRadius);
        startBreakPoint = CIRCLE_BREAK_POINT.THIRD;
        clockwise = true;
    }
    return new SnakeMoveArc(center, newDirection, startBreakPoint, this.turnRadius, this.turnDistance, !clockwise);
}

/**
 * @param {CanvasTarget} target
 * @return {Snake}
 */
Snake.prototype.draw = function (target) {
    if (this.needRedraw) {
        const pathCollection = [];
        for (let idx = 0; idx < this.movement.length; idx++) {
            pathCollection.push(this.movement[idx].generatePath());
        }
        this.pathCollection = pathCollection;
        this.needRedraw = false;
    }

    if (this.hole !== null) {
        this.hole.draw(target);
    }
    target.drawPathCollectionWithStyle(this.pathCollection, this.borderDrawStyle);
    target.drawPathCollectionWithStyle(this.pathCollection, this.drawStyle);
    return this;
}

/**
 * @param {CanvasTarget} target
 * @return {boolean}
 */
Snake.prototype.isCollidingWithHimself = function (target) {
    // we want to remove first element in path, because it's current pos and we always colliding with it
    return target.isPointCollidingWithPathStroke(this.pathCollection.slice(1), this.coords);
}

/**
 * @param {CanvasTarget} target
 * @param {Coords} coords
 * @return {boolean}
 */
Snake.prototype.isCollidingWithCoords = function (target, coords) {
    return target.isPointCollidingWithPathStroke(this.pathCollection, coords);
}

/**
 * @return {string}
 */
Snake.prototype.getDirection = function () {
    return this.direction;
}

export default Snake;
